import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getGPTResult } from '../api'
import { useEffect, useState } from 'react'
import '../css/Wonyoung.scss'
import '../css/Common.scss'

const ResponseMessage = ({
  responseMessage,
  contentWidth,
  imageRandomNo,
}: any) => {
  let padding = 25
  let width = contentWidth - 2 * padding
  let ratio = 0.35
  let height = width * ratio

  return (
    <>
      <div
        style={{
          zIndex: 10,
          position: 'fixed',
          left: padding,
          top: height + padding * 2,
        }}
      >
        <div style={{ width: width, height: height }}>
          <img
            width={width}
            height={height}
            src={`/wonyoung/response${imageRandomNo}.png`}
            alt=""
          />
          <p
            style={{
              color: 'white',
              backgroundColor: 'rgba(255,255,255,0)',
              textAlign: 'center',
              position: 'fixed',
              left: padding,
              top: height + padding * 2,
              width: width,
              height: height,
            }}
            className="wonyoung-text-area-style"
          >
            {responseMessage}
          </p>
        </div>
      </div>
    </>
  )
}
const RequestMessage = ({ requestMessage, contentWidth }: any) => {
  let storage = sessionStorage
  const [customMessageResult, setCustomMessageResult] = useState('')
  const [messageSet, isMessageSet] = useState(false)

  const customMessage = storage.getItem('10message')
  useEffect(() => {
    if (customMessage && !messageSet) {
      setCustomMessageResult(customMessage)
      isMessageSet(true)
    }
  }, [customMessage])

  let padding = 25
  let ratio = 0.35
  const width = contentWidth - padding * 2
  const height = width * ratio
  return (
    <>
      <div
        style={{
          zIndex: 10,
          position: 'fixed',
          left: padding,
          top: padding,
        }}
      >
        <div style={{ width: contentWidth - padding * 2, height: height }}>
          <img
            width={width}
            height={height}
            src="/wonyoung/request.png"
            alt=""
          />
          <p
            style={{
              backgroundColor: 'rgba(255,255,255,0)',
              textAlign: 'center',
              position: 'fixed',
              left: padding,
              top: padding,
              width: width,
              height: height,
            }}
            className="wonyoung-text-area-style"
          >
            {requestMessage}
          </p>
        </div>
      </div>
    </>
  )
}

function Button({ message, onClick, contentWidth, color }: any) {
  return (
    <button
      onClick={onClick}
      className="button-check"
      style={{
        backgroundColor: color,
        zIndex: 4,
        width: contentWidth / 2,
      }}
    >
      <span>{message}</span>
    </button>
  )
}

export default function GPTResult() {
  const { resultUuid } = useParams()
  const resultQuery = useQuery(['uuid', resultUuid], getGPTResult)
  const [requestMessage, setRequestMessage] = useState('')
  const [responseMessage, setResponseMessage] = useState('')
  const nav = useNavigate()
  useEffect(() => {
    if (resultQuery && resultQuery.data) {
      setRequestMessage(resultQuery.data.request_message)
      setResponseMessage(resultQuery.data.response_message)
    }
  }, [resultQuery.data])
  const onClickButton = () => {
    nav('/wonyoung4go')
  }
  const [contentWidth, setContentWidth] = useState(600)
  const [isContentWidthSet, setIsContentWidthSet] = useState(false)
  let storage = sessionStorage

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min) // 최소값 이상으로 반올림
    max = Math.floor(max) // 최대값 이하로 버림
    return Math.floor(Math.random() * (max - min + 1)) + min // [min, max] 범위의 정수 반환
  }

  const [imageRandomNo, setImageRandomNo] = useState(getRandomInt(1, 5))
  useEffect(() => {
    if (!isContentWidthSet) {
      if (window.innerWidth < 600) {
        // 처음에 한번만 width를 세팅하도록 한다.
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', window.innerWidth.toString())
        setContentWidth(window.innerWidth)
      } else {
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', '400')
        setContentWidth(400)
      }
    }
  }, [])

  const onClickShare = async () => {
    let uuid = storage.getItem('gpt_uuid')
    let link = `flowfollow.net/wonyoung4go/${uuid}`
    await navigator.clipboard.writeText(link)
    let alertMessage = `링크가 복사되었습니다.${link}`
    alert(alertMessage)
  }
  const keyColors = ['#CB004F', '#8F0DCE', '#E84273', '#536DFF', '#CC1E8E']

  return (
    <>
      <div style={{ width: contentWidth }}>
        <RequestMessage
          requestMessage={requestMessage}
          contentWidth={contentWidth}
        />
        <ResponseMessage
          responseMessage={responseMessage}
          contentWidth={contentWidth}
          imageRandomNo={imageRandomNo}
        />
        <img
          style={{ zIndex: -2, position: 'fixed', top: 0 }}
          src={`/wonyoung/back${imageRandomNo}.png`}
          width={contentWidth}
        />
        <img
          src={`/wonyoung/won${imageRandomNo}.png`}
          style={{
            zIndex: -1,
            position: 'fixed',
            bottom: 0,
            height: 'auto',
            width: contentWidth,
          }}
          width={contentWidth}
        />
        <div style={{ display: 'flex', position: 'fixed', bottom: 0 }}>
          <Button
            message={'공유하기♥️'}
            onClick={onClickShare}
            contentWidth={contentWidth}
            color="#000000"
          />
          <Button
            message={'다시쓰기♥️'}
            onClick={onClickButton}
            contentWidth={contentWidth}
            color={keyColors[imageRandomNo - 1]}
          />
        </div>
      </div>
    </>
  )
}
