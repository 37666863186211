import { Button, VStack, Heading, Text } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
export default function NotFound() {
  const nav = useNavigate()
  const onClickButton = () => {
    if (document.URL.split('/')[3] == 'order') {
      nav(`/order/${document.URL.split('/')[4]}/${document.URL.split('/')[5]}`)
    } else if (document.URL.split('/')[3] == 'shop-admin') {
      nav(
        `/shop-admin/${document.URL.split('/')[4]}/${
          document.URL.split('/')[5]
        }/${document.URL.split('/')[6]}`,
      )
    } else {
      nav(`/`)
    }
  }
  return (
    <VStack justifyContent={'center'} minH={'100vh'}>
      <Heading>Page not found</Heading>
      <Text>페이지에 뭔가 문제가 있습니다. 주문을 다시 시작해주세요</Text>
      <Button onClick={onClickButton}>Go Home</Button>
    </VStack>
  )
}
