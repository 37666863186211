import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */
export default function RouteChangeTracker() {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  // localhost는 기록하지 않음
  useEffect(() => {
    if (
      !window.location.href.includes('localhost') &&
      !(
        process.env.REACT_APP_BACKEND_TARGET_DOMAIN ===
        'https://backend.ovening.net/api/v1/'
      )
    ) {
      ReactGA.initialize(
        process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID as string,
      )
      setInitialized(true)
    }
  }, [])

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname })
      ReactGA.send('pageview')
    }
  }, [initialized, location])
}
