import { useMutation, useQuery } from '@tanstack/react-query'
import '../css/OrderCheck.scss'
import '../css/Buttons.scss'
import '../css/Common.scss'
import '../css/Wonyoung.scss'
import { useState, useEffect } from 'react'
import { getGPTExamples, getGPTMessage, sendSlackMessage } from '../api'
import { useNavigate } from 'react-router-dom'

export function SubmitButton({
  isLoading,
  message,
  onClick,
  contentWidth,
  keyColor,
}: any) {
  console.log(keyColor)
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="button-submit"
      style={{
        backgroundColor: keyColor,
        zIndex: 4,
        width: contentWidth,
      }}
    >
      {message}
    </button>
  )
}

export default function Home() {
  const nav = useNavigate()

  const onClickButton = () => {
    nav(`/wonyoung4go/`)
  }
  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min) // 최소값 이상으로 반올림
    max = Math.floor(max) // 최대값 이하로 버림
    return Math.floor(Math.random() * (max - min + 1)) + min // [min, max] 범위의 정수 반환
  }

  const [imageRandomNo, setImageRandomNo] = useState(getRandomInt(1, 5))
  const [contentWidth, setContentWidth] = useState(600)
  const [isContentWidthSet, setIsContentWidthSet] = useState(false)
  useEffect(() => {
    if (!isContentWidthSet) {
      if (window.innerWidth < 600) {
        // 처음에 한번만 width를 세팅하도록 한다.
        setIsContentWidthSet(true)
        setContentWidth(window.innerWidth)
      } else {
        setIsContentWidthSet(true)
        setContentWidth(400)
      }
    }
  }, [])
  const keyColors = ['#CB004F', '#8F0DCE', '#E84273', '#536DFF', '#CC1E8E']
  return (
    <>
      <div
        style={{
          width: contentWidth,
          height: window.innerHeight,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <img
          style={{
            zIndex: -2,
            height: '100%',
            width: contentWidth,
            objectFit: 'cover',
            position: 'fixed',
            top: 0,
          }}
          src={`/wonyoung/back${imageRandomNo}.png`}
        />
        <img
          src={`/wonyoung/won${imageRandomNo}.png`}
          style={{
            zIndex: -1,
            position: 'absolute',
            bottom: '60px',
            height: 'auto',
            width: contentWidth,
          }}
        />
        <SubmitButton
          message={'원영아 도와줘!!'}
          onClick={onClickButton}
          contentWidth={contentWidth}
          keyColor={keyColors[imageRandomNo - 1]}
        />
      </div>
    </>
  )
}
