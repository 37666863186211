import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import router from './router'
import { RouterProvider, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactGA from 'react-ga'
import RouteChangeTracker from './components/RouteChangeTracker'

ReactGA.event({
  category: 'User',
  action: 'Created an Account',
})
ReactGA.exception({
  description: 'An error ocurred',
  fatal: true,
})
const client = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <RouteChangeTracker>
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ChakraProvider>
        <RouterProvider router={router} />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  // </RouteChangeTracker>
)
