import { Outlet } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Box } from '@chakra-ui/react'
import RouteChangeTracker from './RouteChangeTracker'
import TagManager from 'react-gtm-module'
import { ToastContainer } from 'react-toastify'
export default function Root() {
  RouteChangeTracker()
  const tagManagerArgs = {
    gtmId: 'GTM-KNPG4P7',
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <Box>
      <Outlet />
      <ReactQueryDevtools />
      <ToastContainer />
    </Box>
  )
}
