import { useMutation, useQuery } from '@tanstack/react-query'
import '../css/OrderCheck.scss'
import '../css/Buttons.scss'
import '../css/Common.scss'
import '../css/Wonyoung.scss'
import { useEffect, useState } from 'react'
import { getGPTExamples, getGPTMessage } from '../api'
import { useNavigate } from 'react-router-dom'

const ExampleText = ({ example }: any) => {
  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <div>입력 : {example.request_message}</div>
      <div>출력 : {example.response_message}</div>
    </div>
  )
}

export function SubmitButton({
  isLoading,
  message,
  onClick,
  contentWidth,
}: any) {
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="button-submit"
      style={{
        zIndex: 4,
        width: contentWidth,
      }}
    >
      <span>{isLoading ? '원영적 사고회로 작동중...' : message}</span>
    </button>
  )
}

export const CustomizeMessage = ({ isLoading, contentWidth }: any) => {
  let placeHolder = '나쁜말 써보기♥️'

  let storage = sessionStorage
  const [customMessageResult, setCustomMessageResult] = useState('')
  const [messageSet, isMessageSet] = useState(false)

  const customMessage = storage.getItem('10message')
  useEffect(() => {
    if (customMessage && !messageSet) {
      setCustomMessageResult(customMessage)
      isMessageSet(true)
    }
  }, [customMessage])

  const [currentMessageCount, setCurrentMessageCount] = useState(0)
  const onChangeCustomMessage = (e: any) => {
    if (e.target.value.length > 100) {
      return
    }
    setCurrentMessageCount(e.target.value.length)
    setCustomMessageResult(e.target.value)
    storage.setItem('10message', e.target.value as string)
  }
  let padding = 20
  let width = contentWidth - padding * 2
  let ratio = 0.35
  let height = width * ratio
  return (
    <>
      <div
        style={{
          zIndex: 10,
          position: 'fixed',
          left: padding,
          top: (window.innerHeight - 140) / 2,
        }}
      >
        <div style={{ width: width, height: height }}>
          <img
            height={height}
            width={width}
            src="/wonyoung/request.png"
            alt=""
          />
          <textarea
            disabled={isLoading}
            style={{
              backgroundColor: 'rgba(255,255,255,0)',
              textAlign: 'center',
              position: 'fixed',
              left: padding,
              top: (window.innerHeight - height) / 2,
              width: width,
              height: height,
            }}
            placeholder={placeHolder}
            className="wonyoung-text-area-style"
            onChange={onChangeCustomMessage}
            value={customMessageResult as string}
          ></textarea>
        </div>
        <div
          style={{
            width: width,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            bottom: 20,
          }}
        >
          <span style={{ fontFamily: 'Galmuri11', opacity: 0.4 }}>
            {currentMessageCount}/100
          </span>
        </div>
      </div>
    </>
  )
}
export default function GPT() {
  let storage = sessionStorage
  const nav = useNavigate()
  const uploadMessageMutation = useMutation(getGPTMessage, {
    onSuccess: (response: any) => {
      storage.setItem('gpt_response', response.response_message)
      storage.setItem('gpt_uuid', response.uuid)
      nav(`/wonyoung4go/${response.uuid}`)
    },
  })

  const onClickSubmit = () => {
    let message = storage.getItem('10message')
    uploadMessageMutation.mutate({ message })
  }

  const [contentWidth, setContentWidth] = useState(600)
  const [isContentWidthSet, setIsContentWidthSet] = useState(false)
  useEffect(() => {
    if (!isContentWidthSet) {
      if (window.innerWidth < 600) {
        // 처음에 한번만 width를 세팅하도록 한다.
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', window.innerWidth.toString())
        setContentWidth(window.innerWidth)
      } else {
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', '400')
        setContentWidth(400)
      }
    }
  }, [])
  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min) // 최소값 이상으로 반올림
    max = Math.floor(max) // 최대값 이하로 버림
    return Math.floor(Math.random() * (max - min + 1)) + min // [min, max] 범위의 정수 반환
  }
  const [imageRandomNo, setImageRandomNo] = useState(getRandomInt(1, 5))
  const keyColors = ['#CB004F', '#8F0DCE', '#E84273', '#536DFF', '#CC1E8E']

  return (
    <>
      <div
        style={{
          width: contentWidth,
          height: window.innerHeight,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <CustomizeMessage
          isLoading={uploadMessageMutation.isLoading}
          contentWidth={contentWidth}
        />
        <img
          style={{
            zIndex: -2,
            height: '100%',
            width: contentWidth,
            objectFit: 'cover',
            position: 'fixed',
            top: 0,
          }}
          src={`/wonyoung/back5.png`}
        />

        <SubmitButton
          isLoading={uploadMessageMutation.isLoading}
          message={'원영아 도와줘!!'}
          onClick={onClickSubmit}
          keyColor={keyColors[imageRandomNo - 1]}
          contentWidth={contentWidth}
        />
      </div>
    </>
  )
}
